<template>
  <div id="main" class="warp-cont">
    <!-- route-view 显示一个route所对应的页面 -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#main {
  width: 100%;
  height: 100%;
}
html,
body {
  height: 100%;
  overflow: hidden;
}
.warp-cont {
  position: fixed;
  position: -ms-device-fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>
