import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.config.productionTip = false
Vue.use(Element);
import VueScroller from "vue-scroller";
Vue.use(VueScroller);
Vue.prototype.$goRoute = function (index) {
  this.$router.push(index);
};
new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
