<template>
	<div>
		<scroller :on-infinite="infinite" :on-refresh="refresh" :noDataText="noDataText" ref="my_scroller">
			<div class="mode_box">
				<router-link class="mode_item" v-for="(item,index) in modelList" :key="index"
					:to="{ path: '/detail', query: { modelId: item.id } }">
					<img class="mode_img" :src="item.image_url" />
					<div class="mode_title">{{item.title}}</div>
				</router-link>
			</div>
		</scroller>
	</div>
</template>

<script>
	import axios from "axios";
	export default {
		name: "home",
		data() {
			return {
				// 遮罩层
				loading: true,
				// 项目表格数据
				listStatus: "loading",
				modelList: [],
				// 查询参数
				queryParams: {
					page: 1,
				},
				noDataText: "--我也是有底线的--",
				baseUrl: "https://hlm.halomu.com",
				// baseUrl: "https://guanli.halomu.com",
			};
		},

		mounted() {
			this.getList();
		},
		methods:{
			getList(){
				let that = this;
				axios
					.get(`${that.baseUrl}/addons/hlm/api.Goods/goodsList`,that.queryParams)
					.then((response) => {
						let data = response.data.data;
						let list = data.list;
						if (list.length < data.limit) {
							that.listStatus="nomore",that.$refs.my_scroller.finishInfinite(true);
						} else {
							that.listStatus="more",that.$refs.my_scroller.finishInfinite(false);
						}
						that.modelList = [...that.modelList, ...list];
						that.loading = false;
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			infinite(){
				let that = this;
				if(that.listStatus==="more") that.queryParams.pageNum++,that.listStatus="loading",that.getList();
			},
			refresh: function() {
				let that = this;
				that.modelList = [],that.queryParams.page = 1,that.listStatus="loading",that.getList();
				that.timeout = setTimeout(() => {
					that.$refs.my_scroller.finishPullToRefresh()
				}, 1500)
			},
		}
	};
</script>

<style>
	#page {
		width: 100%;
		height: 100%;
	}

	.mode_box {
		padding: 20px 1.6% 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

	}

	.mode_box a {
		text-decoration: none;
	}

	.mode_item {
		width: 29%;
		margin: 0 2%;
	}

	.mode_img {
		width: 100%;
		border-radius: 6px;
	}

	.mode_title {
		width: 100%;
		height: 30px;
		font-size: 14px;
		color: #333333;
		line-height: 30px;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>
